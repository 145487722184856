import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react"
import { redirectUrls } from "@/utils/redirectsUrls.ts"
import Cookies from "js-cookie"
import FallbackLoading from "@/pages/FallbackLoading.tsx"

export const SessionContext = createContext({
    redirectUrl: "",
    parcours: "classic",
    createOrUpdateCookieAndState: (defaultValues?: {
        redirectUrl: string | null
        parcours: string | null
        abTest50ct: boolean | null
        abTestInfosTarifsBold: boolean | null
    }) => {
        console.log(defaultValues)
    },
    abTest50ct: false,
    abTestInfosTarifsBold: false,
})

const CookieName = "xr"

type ParcoursType = "classic" | "acquiz"

type CookieValueType = {
    redirectUrl: string
    parcours: ParcoursType
    abTest50ct: boolean
    abTestInfosTarifsBold: boolean
}

export const SessionProvider = ({ children }: PropsWithChildren) => {
    const [isLoaded, setLoaded] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string>("")
    const [parcours, setParcours] = useState<ParcoursType>("classic")
    const [abTest50ct, setAbTest50ct] = useState<boolean>(false)
    const [abTestInfosTarifsBold, setAbTestInfosTarifsBold] = useState<boolean>(false)

    const createOrUpdateCookieAndState = (defaultValues?: {
        redirectUrl: string | null
        parcours: string | null
        abTest50ct: boolean | null
        abTestInfosTarifsBold: boolean | null
    }) => {
        const cookieValue = {} as CookieValueType

        //  Redirect Url
        const possibleUrls = window.location.href.includes("local")
            ? redirectUrls.local
            : window.location.href.includes("staging") || window.location.href.includes("feature")
            ? redirectUrls.staging
            : import.meta.env.VITE_VERTICALE === "taxi"
            ? redirectUrls.prodTaxi
            : import.meta.env.VITE_VERTICALE === "ici"
            ? redirectUrls.prodIci
            : redirectUrls.prodResto

        if (defaultValues && defaultValues.redirectUrl !== null) {
            const findingUrl = possibleUrls.find((url) => url.url === defaultValues.redirectUrl)

            if (findingUrl) {
                cookieValue.redirectUrl = defaultValues.redirectUrl
            } else {
                const random = Math.random() * 100

                let selectedUrl = null
                let sum = 0

                for (const url of possibleUrls) {
                    sum += url.lb
                    if (random <= sum) {
                        selectedUrl = url.url
                        break
                    }
                }

                if (!selectedUrl) {
                    selectedUrl = possibleUrls[0].url
                }

                cookieValue.redirectUrl = selectedUrl
            }
        } else {
            const random = Math.random() * 100

            let selectedUrl = null
            let sum = 0

            for (const url of possibleUrls) {
                sum += url.lb
                if (random <= sum) {
                    selectedUrl = url.url
                    break
                }
            }

            if (!selectedUrl) {
                selectedUrl = possibleUrls[0].url
            }

            cookieValue.redirectUrl = selectedUrl
        }

        if (
            defaultValues &&
            defaultValues.parcours !== null &&
            defaultValues.parcours !== undefined &&
            (location.pathname === "/reserver-un-restaurant" || location.pathname === "/reserver-un-taxi") ===
                (defaultValues.parcours === "acquiz")
        ) {
            cookieValue.parcours = defaultValues.parcours as ParcoursType
        } else {
            if (location.pathname === "/reserver-un-restaurant" || location.pathname === "/reserver-un-taxi") {
                cookieValue.parcours = "acquiz"
            } else {
                cookieValue.parcours = "classic"
            }
        }

        if (defaultValues && defaultValues.abTest50ct !== null) {
            cookieValue.abTest50ct = defaultValues.abTest50ct
        } else {
            // cookieValue.abTest50ct = Math.random() < 0.5
            cookieValue.abTest50ct = false
        }

        if (defaultValues && defaultValues.abTestInfosTarifsBold !== null) {
            cookieValue.abTestInfosTarifsBold = defaultValues.abTestInfosTarifsBold
        } else {
            const random = Math.random()
            cookieValue.abTestInfosTarifsBold = random < 0.5
        }

        // Set the cookie
        Cookies.set(CookieName, JSON.stringify(cookieValue))

        // Update the state
        if (cookieValue.redirectUrl !== redirectUrl) {
            setRedirectUrl(cookieValue.redirectUrl)
        }
        if (cookieValue.parcours !== parcours) {
            setParcours(cookieValue.parcours)
        }
        if (cookieValue.abTest50ct !== abTest50ct) {
            setAbTest50ct(cookieValue.abTest50ct)
        }
        if (cookieValue.abTestInfosTarifsBold !== abTestInfosTarifsBold) {
            setAbTestInfosTarifsBold(cookieValue.abTestInfosTarifsBold)
        }

        setLoaded(true)
    }

    useEffect(() => {
        const cookie = Cookies.get(CookieName)
        if (cookie) {
            try {
                const parsedCookie = JSON.parse(cookie) as CookieValueType

                createOrUpdateCookieAndState({
                    redirectUrl: parsedCookie.redirectUrl ?? null,
                    parcours: parsedCookie.parcours ?? null,
                    abTest50ct: parsedCookie.abTest50ct ?? null,
                    abTestInfosTarifsBold: parsedCookie.abTestInfosTarifsBold ?? null,
                })
            } catch (err) {
                createOrUpdateCookieAndState()
            }
        } else {
            createOrUpdateCookieAndState()
        }
    }, [])

    return isLoaded ? (
        <SessionContext.Provider
            value={{
                redirectUrl,
                parcours,
                createOrUpdateCookieAndState,
                abTest50ct,
                abTestInfosTarifsBold,
            }}
        >
            {children}
        </SessionContext.Provider>
    ) : (
        <FallbackLoading />
    )
}

export const useRedirectUrl = () => useContext(SessionContext).redirectUrl
export const useAcquiz = () => useContext(SessionContext).parcours === "acquiz"

export const useAbTest50ct = () => useContext(SessionContext).abTest50ct
export const useAbTestInfosTarifsBold = () => useContext(SessionContext).abTestInfosTarifsBold
export const useSession = () => useContext(SessionContext)
