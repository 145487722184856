import * as amplitude from "@amplitude/analytics-browser"
import { useSession } from "@/contexts/SessionContext.tsx"
import { useLocation } from "react-router-dom"
import { isCookieRefused } from "@/utils/index.ts"

export const useAmplitude = () => {
    const session = useSession()
    const pathname = useLocation().pathname
    const verticale = import.meta.env.VITE_VERTICALE

    if (verticale === "resto" && pathname === "/reserver-un-restaurant") {
        session.parcours = "acquiz"
    }

    return (eventName: string, eventProperties?: Record<string, unknown>) => {
        try {
            if (isCookieRefused) {
                console.info("Cookie refused")
            } else {
                return amplitude.track(eventName, {
                    website: window.location.origin,
                    currentUrl: window.location.href,
                    verticale,
                    session: session,
                    abTest50ct: session.abTest50ct,
                    "AB-PAY-InfosTarifsBoldV2": session.abTestInfosTarifsBold ? "B" : "A",
                    ...(eventProperties || {}),
                })
            }
        } catch (error) {
            console.error("Error while tracking event", error)
        }
    }
}
