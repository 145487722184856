export const localUrls = [
    {
        url: "http://local-book.book-ici:3002/?ps=1", // Hipay - 1
        lb: 0,
    },
    {
        url: "http://local-book.book-ici:3002/?ps=2", // Hipay - 2
        lb: 0,
    },
    {
        url: "http://local-book.top-resrv:3002/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "http://local-book.reso-pro:3002/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "http://local-book.reso-pro:3002/?ps=1", // Hipay - 1
        lb: 0,
    },
    {
        url: "http://local-book.reso-pro:3002/?ps=2", // Hipay - 2
        lb: 0,
    },
    {
        url: "http://local-book.reserv-go:3002/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "http://local-book.reserv-go:3002/?ps=1", // HiPay - 1
        lb: 0,
    },
    {
        url: "http://local-book.reserv-go:3002/?ps=2", // HiPay - 2
        lb: 0,
    },
    {
        url: "http://local-book.servi-life:3002/?ps=1", // HiPay - 1
        lb: 0,
    },
    {
        url: "http://local-book.servi-life:3002/?ps=2", // HiPay - 2
        lb: 0,
    },
    {
        url: "http://local-book.servi-life:3002/?ps=et", // EasyTransac
        lb: 50,
    },
    {
        url: "http://local-book.easy-rsv:3002/?ps=1", // HiPay - 1
        lb: 0,
    },
    {
        url: "http://local-book.easy-rsv:3002/?ps=2", // HiPay - 2
        lb: 0,
    },
    {
        url: "http://local-book.easy-rsv:3002/?ps=et", // EasyTransac
        lb: 50,
    },
    {
        url: "http://local-book.reservup:3002/?ps=0", // Stripe - 0
        lb: 0,
    },
    {
        url: "http://local-book.now-servi:3002/?ps=0", // Stripe - 0
        lb: 0,
    },
]
export const stagingUrls = [
    {
        url: "https://staging-booking.book-ici.com/?ps=1", // Hipay - 1
        lb: 12,
    },
    {
        url: "https://staging-booking.book-ici.com/?ps=2", // Hipay - 2
        lb: 11,
    },
    {
        url: "https://staging-booking.book-ici.com/?ps=et", // EasyTransac
        lb: 0,
    },
    {
        url: "https://staging-booking.top-resrv.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://staging-booking.reso-pro.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://staging-booking.reso-pro.com/?ps=1", // Hipay - 1
        lb: 12,
    },
    {
        url: "https://staging-booking.reso-pro.com/?ps=2", // Hipay - 2
        lb: 11,
    },
    {
        url: "https://staging-booking.reso-pro.com/?ps=et", // EasyTransac
        lb: 0,
    },
    {
        url: "https://staging-booking.reserv-go.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://staging-booking.reserv-go.com/?ps=1", // HiPay - 1
        lb: 5,
    },
    {
        url: "https://staging-booking.reserv-go.com/?ps=2", // HiPay - 2
        lb: 5,
    },
    {
        url: "https://staging-booking.reserv-go.com/?ps=et", // EasyTransac
        lb: 0,
    },
    {
        url: "https://staging-booking.servi-life.com/?ps=1", // HiPay - 1
        lb: 11,
    },
    {
        url: "https://staging-booking.servi-life.com/?ps=2", // HiPay - 2
        lb: 11,
    },
    {
        url: "https://staging-booking.servi-life.com/?ps=et", // EasyTransac
        lb: 0,
    },
    {
        url: "https://staging-booking.easy-rsv.com/?ps=1", // HiPay - 1
        lb: 11,
    },
    {
        url: "https://staging-booking.easy-rsv.com/?ps=2", // HiPay - 2
        lb: 11,
    },
    {
        url: "https://staging-booking.easy-rsv.com/?ps=et", // EasyTransac
        lb: 0,
    },
    {
        url: "https://staging-booking.reservup.net/?ps=0", // Stripe - 0
        lb: 0,
    },
    {
        url: "https://staging-booking.now-servi.com/?ps=0", // Stripe - 0
        lb: 0,
    },
]
export const prodUrlsTaxi = [
    {
        url: "https://booking.book-ici.com/?ps=1", // Hipay - 1
        lb: 16,
    },
    {
        url: "https://booking.book-ici.com/?ps=2", // Hipay - 2
        lb: 17,
    },
    {
        url: "https://booking.top-resrv.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=1", // Hipay - 1
        lb: 17,
    },
    {
        url: "https://booking.reso-pro.com/?ps=2", // Hipay - 2
        lb: 17,
    },
    {
        url: "https://booking.reserv-go.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reserv-go.com/?ps=1", // HiPay - 1
        lb: 16,
    },
    {
        url: "https://booking.reserv-go.com/?ps=2", // HiPay - 2
        lb: 17,
    },
    {
        url: "https://booking.servi-life.com/?ps=1", // HiPay - 1
        lb: 0,
    },
    {
        url: "https://booking.servi-life.com/?ps=2", // HiPay - 2
        lb: 0,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=1", // HiPay - 1
        lb: 0,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=2", // HiPay - 2
        lb: 0,
    },
    {
        url: "https://booking.reservup.net/?ps=0", // Stripe - 0
        lb: 0,
    },
    {
        url: "https://booking.now-servi.com/?ps=0", // Stripe - 0
        lb: 0,
    },
]
export const prodUrlsResto = [
    {
        url: "https://booking.book-ici.com/?ps=1", // Hipay - 1
        lb: 10,
    },
    {
        url: "https://booking.book-ici.com/?ps=2", // Hipay - 2
        lb: 10,
    },
    {
        url: "https://booking.top-resrv.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=1", // Hipay - 1
        lb: 10,
    },
    {
        url: "https://booking.reso-pro.com/?ps=2", // Hipay - 2
        lb: 10,
    },
    {
        url: "https://booking.reserv-go.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reserv-go.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.reserv-go.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.servi-life.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.servi-life.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.reservup.net/?ps=0", // Stripe - 0
        lb: 0,
    },
    {
        url: "https://booking.now-servi.com/?ps=0", // Stripe - 0
        lb: 0,
    },
]

export const prodUrlsIci = [
    {
        url: "https://booking.book-ici.com/?ps=1", // Hipay - 1
        lb: 10,
    },
    {
        url: "https://booking.book-ici.com/?ps=2", // Hipay - 2
        lb: 10,
    },
    {
        url: "https://booking.top-resrv.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reso-pro.com/?ps=1", // Hipay - 1
        lb: 10,
    },
    {
        url: "https://booking.reso-pro.com/?ps=2", // Hipay - 2
        lb: 10,
    },
    {
        url: "https://booking.reserv-go.com/?ps=0", // Quaife - 0
        lb: 0,
    },
    {
        url: "https://booking.reserv-go.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.reserv-go.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.servi-life.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.servi-life.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=1", // HiPay - 1
        lb: 10,
    },
    {
        url: "https://booking.easy-rsv.com/?ps=2", // HiPay - 2
        lb: 10,
    },
    {
        url: "https://booking.reservup.net/?ps=0", // Stripe - 0
        lb: 0,
    },
    {
        url: "https://booking.now-servi.com/?ps=0", // Stripe - 0
        lb: 0,
    },
]

export const redirectUrls = {
    local: localUrls.filter((url) => url.lb && url.lb > 0),
    staging: stagingUrls.filter((url) => url.lb && url.lb > 0),
    prodTaxi: prodUrlsTaxi.filter((url) => url.lb && url.lb > 0),
    prodResto: prodUrlsResto.filter((url) => url.lb && url.lb > 0),
    prodIci: prodUrlsIci.filter((url) => url.lb && url.lb > 0),
}
