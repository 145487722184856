export const faqData = [
    {
        title: "Réservations et fonctionnalités",
        questions: [
            {
                question: "Comment ça marche ?",
                answer: "Pour réserver une table, commencez par entrer vos critères sur notre site. Après avoir saisi vos coordonnées et profité d'une période d'essai pour 1ct, notre conciergerie partenaire prendra en charge votre demande, vous aidant à choisir le restaurant idéal selon vos préférences.",
            },
            {
                contentInforBar:
                    "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par le restaurant. Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives valides.",
            },
            {
                question: "Comment puis-je savoir que ma réservation a été acceptée ?",
                answer: "Vous recevrez une confirmation par email ou SMS avec tous les détails de votre réservation.",
            },
            {
                question: "Que faire si je veux modifier ou annuler ma réservation ?",
                answer: "Resto Reza ne gère pas directement vos réservations. Pour toute modification ou annulation, il est nécessaire de contacter directement le service de conciergerie qui a géré votre réservation initiale. Ils vous fourniront l'assistance nécessaire pour ajuster ou annuler votre réservation selon vos besoins.",
            },
            {
                question: "Quand dois-je régler ma facture ?",
                answer: "Vous réglez directement au restaurant à la fin de votre repas.",
            },
            {
                question: "Resto Reza est-il un réseau de restaurants ?",
                answer: "Non, Resto Reza n'est pas un réseau de restaurants. Nous ne gérons pas directement vos réservations avec les restaurants. Nous facilitons uniquement la mise en relation entre nos clients et les services de conciergerie partenaires qui gèrent les réservations dans les meilleurs établissements.",
            },
        ],
    },
    {
        title: "Politiques et procédures",
        questions: [
            {
                question: "Comment fonctionne le processus de remboursement si j'annule ma réservation ?",
                answer: "Les remboursements sont traités rapidement si l'annulation respecte notre politique définie. La garantie satisfait ou remboursé de la conciergerie vous assure un remboursement dans 100% des cas.",
            },
            {
                question: "Y a-t-il des frais cachés lors de la réservation ?",
                answer: "Non, tous les frais sont clairement indiqués avant la finalisation de votre réservation.",
            },
        ],
    },
    {
        title: "Service client et assistance",
        questions: [
            {
                question: "Comment contacter le service client et quels sont leurs horaires ?",
                answer: "Notre service client est disponible par email du lundi au dimanche de 7h à 22h.",
            },
            {
                question: "Que faire si je dois annuler ma réservation ou si j'ai des problèmes avec le restaurant ?",
                answer: "Resto Reza ne gère pas directement les réservations. Pour annuler ou résoudre un problème, vous devez contacter directement la conciergerie qui a traité votre réservation initiale. Ils sont responsables de fournir toute l'assistance nécessaire pour gérer ou modifier vos arrangements. Pour rappel, la conciergerie fournit un assistant qui vous assiste et vous aide dans vos démarches. Mais elles n’ont aps de partenariat avec les restaurants et ne pourront pas assurer la résolution d’un problème avec le restaurant.",
            },
        ],
    },
]
