import type { Components } from "react-markdown"

export const markdownTailwindComponentsConfig: Partial<Components> = {
    // h1 selon votre base
    h1: (props) => <h1 className="text-2xl font-semibold my-4" {...props} />,
    // Titre de niveau 2
    h2: (props) => <h2 className="text-xl font-semibold my-3" {...props} />,
    // Titre de niveau 3
    h3: (props) => <h3 className="text-lg font-semibold my-3" {...props} />,
    // Paragraphe
    p: (props) => <p className="mb-4" {...props} />,
    // Liste à puces
    ul: (props) => <ul className="list-disc ps-7 mb-4" {...props} />,
    // Élément de liste
    li: (props) => <li className="mb-1" {...props} />,
    // Texte en gras
    strong: (props) => <strong className="font-semibold" {...props} />,
    // Lien
    a: ({ href, ...rest }) => (
        <a href={href} className="text-inherit hover:underline" target="_blank" rel="noopener noreferrer" {...rest} />
    ),
    // Séparateur (---)
    hr: (props) => <hr className="my-6 border-gray-300" {...props} />,
    // Exemple de blockquote
    blockquote: (props) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
    // Exemple de code inline ou blocs de code
    code: (props) => <code className="bg-gray-100 px-1 py-0.5 rounded text-sm" {...props} />,
}
