export const howItsWorkData = {
    introduce: {
        title: "Comment ça marche ?",
        description:
            "Nous facilitons votre demande de réservation de restaurant; cependant, la confirmation et la gestion sont assurées par nos partenaires de conciergerie. Pour chaque lead transmis à la conciergerie, nous percevons des frais, en vertu de nos accords de partenariat avec ces services de conciergerie.",
    },
    planOuting: {
        image: "/images/restaurant/man-hold-phone.jpeg",
        title: "Planifiez votre sortie",
        icon: "ic-search-2",
        description:
            "Trouvez le restaurant parfait en indiquant vos préférences culinaires et la date souhaitée sur notre site.",
    },
    freeTrial: {
        image: "/images/restaurant/keyboard-typing.png",
        title: "Profitez d’un essai pour 1ct",
        icon: "ic-note",
        class: {},
        description:
            "Inscrivez-vous pour une période d'essai pour 1ct de trois jours avec notre partenaire de conciergerie. Confirmez votre réservation et découvrez leurs services sans aucun engagement.",
    },
    letUsWork: {
        image: "/images/restaurant/consultant.png",
        title: "Laissez votre assistant faire le travail",
        icon: "ic-woment",
        description:
            "Une fois votre demande enregistrée, nos conciergeries partenaires s'occupent de tout. Une fois votre demande enregistrée, nos conciergeries partenaires s'occupent des détails pour vous trouver la meilleure table.",
        contentInfoBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par le restaurant. Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives valides.",
        class: {},
    },
    confirmAndEnjoy: {
        image: "/images/restaurant/goblet.png",
        title: "Confirmez et savourez",
        icon: "ic-restaurant-food",
        description:
            "Votre table est réservée, il ne vous reste plus qu'à vous rendre au restaurant, savourer votre repas et régler l'addition.",
        class: {},
    },
    button: {
        title: "Rechercher un Restaurant",
        url: "/",
    },
}
