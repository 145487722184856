export const ourMissionData = {
    introduce: {
        title: "Notre mission",
        description:
            "Notre objectif est de simplifier la réservation de services en tous genres, qu'il s'agisse de trouver un transport, un prestataire local, ou de bénéficier de services à domicile. Nous collaborons avec des partenaires experts et fiables, garantissant une qualité de service irréprochable, peu importe le secteur.",
    },
    missions: [
        {
            title: "Excellence",
            icon: "ic-diamond",
            description:
                "Nous nous entourons de prestataires rigoureusement sélectionnés pour leur expertise et leur sérieux. Chaque service est soigneusement vérifié pour répondre à des critères exigeants de qualité.",
        },
        {
            title: "Simplicité et accès facilité",
            icon: "ic-mobility",
            description:
                "Nous vous donnons accès à un large éventail de services en un clic, à n'importe quel moment. Planifier un transport, organiser une réparation ou demander un service spécifique n’a jamais été aussi intuitif. Grâce à notre plateforme, vos réservations sont rapides, faciles et sans tracas.",
        },
        {
            title: "Un accompagnement sur-mesure",
            icon: "ic-customer-service",
            description:
                "Disponible tous les jours, notre équipe de support client est à vos côtés pour garantir que chaque étape de votre réservation se passe sans accroc. Que vous ayez une question ou que vous nécessitiez un suivi plus personnalisé, nous sommes là pour vous assister à chaque moment clé.",
        },
        {
            title: "Offre de conciergerie garantie satisfait ou remboursé",
            icon: "ic-shield",
            description:
                "Le service de conciergerie vous offre 3 jours d'essai pour 1ct avec un assistant dédié qui gère vos réservations. Si besoin, la conciergerie propose des alternatives rapides et garantissons satisfaction ou remboursement.",
        },
    ],
    ourValues: {
        title: "Nos valeurs",
        class: { title: "!text-blue-500", itemBox: "md:grid-cols-5" },
        items: [
            {
                icon: "ic-search",
                title: "Clarté",
            },
            {
                icon: "ic-listening",
                title: "Proximité",
            },
            {
                icon: "ic-diamond",
                title: "Exigence",
            },
            {
                icon: "ic-real-time",
                title: "Rapidité",
            },
            {
                icon: "ic-schedule",
                title: "Assistance continue",
            },
        ],
    },
}
