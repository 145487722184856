import { Box } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import remarkGfm from "remark-gfm"
import Markdown from "react-markdown"

import TaxiPrivacy from "./content/privacy-taxi.md?raw"
import RestoPrivacy from "./content/privacy-resto.md?raw"
import IciPrivacy from "./content/privacy-ici.md?raw"
import { dynamicValue } from "@/utils/verticale.ts"
import TwContainer from "@/components/tailwind-components/atoms/TwContainer"
import { markdownTailwindComponentsConfig } from "@/pages/Legals/config"
import LegalDisclaimer from "@/pages/Legals/LegalDisclaimer"

const PrivacyPolicy = () => {
    const isTaxi = import.meta.env.VITE_VERTICALE === "taxi"

    const DynamicContainer = isTaxi ? BoxColumn : TwContainer
    const dynamicContainerProps = isTaxi
        ? {
              mt: { base: "30px", lg: "40px" },
          }
        : {}

    return (
        <Box pb={{ base: "30px", lg: "60px" }} minH="70vh">
            <DynamicContainer {...dynamicContainerProps}>
                <LegalDisclaimer />
                <Box variant="base" color="#616161" textAlign="justify" fontSize={{ base: "14px", lg: "16px" }}>
                    <Markdown remarkPlugins={[remarkGfm]} components={markdownTailwindComponentsConfig}>
                        {dynamicValue({ taxi: TaxiPrivacy, resto: RestoPrivacy, ici: IciPrivacy })}
                    </Markdown>
                </Box>
            </DynamicContainer>
        </Box>
    )
}

export default PrivacyPolicy
