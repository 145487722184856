import { staticRestoData } from "@/constants/local/resto"

export const restoCommon = {
    brandName: "Resto Reza",
    data: staticRestoData,
    pageName: {
        howItsWork: "Fonctionnement",
        ourMission: "Notre mission",
        ourPartner: "Nos partenaires",
        faq: "FAQ",
    },
}
