export const howItsWorkData = {
    introduce: {
        title: "Comment ça marche ?",
        description:
            "Reserve-ici simplifie la gestion de toutes vos réservations, que ce soit pour un service de transport, un artisan à domicile, ou une prestation spécifique. Grâce à notre plateforme intuitive, chaque demande est prise en charge par nos partenaires qualifiés, qui veillent à vous offrir le meilleur service en toute simplicité.",
    },
    planOuting: {
        image: "/images/girl-on-phone.jpeg",
        title: "Précisez votre demande",
        icon: "ic-search-2",
        description:
            "Sélectionnez le service dont vous avez besoin, qu’il s’agisse d’un trajet en taxi, d'une réparation à domicile ou d'une réservation de billets. Entrez les détails spécifiques tels que la date, l'heure et toute autre information nécessaire pour que votre demande soit parfaitement traitée.",
    },
    freeTrial: {
        image: "/images/work-laptop.jpeg",
        title: "Testez nos services",
        icon: "ic-note",
        class: {},
        description:
            "Profitez d'une offre d'essai sans engagement pour découvrir la qualité des services proposés par les assistants de la conciergerie. Inscrivez-vous, effectuez une première demande de réservation et bénéficiez d'une assistance dédiée pour tout type de service, pour 1ct pendant trois jours.",
    },
    letUsWork: {
        image: "/images/girl-call.jpeg",
        title: "Nous nous occupons du reste",
        icon: "ic-woment",
        description:
            "Une fois votre demande validée, les assistants de la conciergerie prennent en charge l'organisation complète du service. Ils vérifient les disponibilités et vous proposent rapidement des solutions adaptées. Si le service initial n’est pas disponible, ils vous proposeront des alternatives correspondant à vos besoins.",
        contentInfoBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par l'établissement (coiffeur, spa, etc.). Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives validées.",
        class: {},
    },
    confirmAndEnjoy: {
        image: "/images/women-work.jpeg",
        title: "Confirmation et suivi",
        icon: "ic-restaurant-food",
        description:
            "Une fois votre réservation confirmée, vous n'avez plus qu'à attendre le jour du service. Que ce soit pour un trajet, un rendez-vous ou une intervention à domicile, les assistants de la conciergerie vous tiendront informé en temps réel pour que tout se passe dans les meilleures conditions.",
        class: {},
    },
    button: {
        title: "Lancer une réservation",
        url: "/",
    },
}
