export default function LegalDisclaimer() {
    if (import.meta.env.VITE_VERTICALE !== "resto") {
        return null
    }

    const brandName: string = import.meta.env.VITE_VERTICALE === "resto" ? "Resto Reza" : "Reserve Ici"
    return (
        <div className="border-3 rounded-md border-[#2571FE] bg-[#EAEFFF] p-4 my-6 lg:my-12">
            <h3 className="text-lg font-bold mb-4 leading-tight">
                IMPORTANT – {brandName} est un comparateur de restaurants qui ne facture jamais ses utilisateurs
            </h3>

            <ol className="list-decimal ps-4 lg:ps-5">
                <li className={"text-s mb-1 leading-6"}>
                    {brandName} est un <strong>comparateur de restaurants.</strong>
                </li>
                <li className={"text-s mb-1 leading-6"}>
                    {brandName} redirige ses utilisateurs vers des{" "}
                    <strong>conciergeries partenaires qui gèrent la demande de réservation.</strong>
                </li>
                <li className={"text-s mb-1 leading-6"}>
                    <strong>{brandName} ne gère donc pas les demandes de réservation</strong> et ne peut en être tenu
                    responsable.
                </li>
                <li className={"text-s mb-1 leading-6"}>
                    Les conciergeries partenaires, qui gèrent les demandes de réservation, sont sélectionnées pour{" "}
                    <strong>la qualité de leur service.</strong>
                </li>
                <li className={"text-s mb-1 leading-6"}>
                    <strong>{brandName} ne facture jamais ses utilisateurs.</strong>
                </li>
                <li className={"text-s mb-1 leading-6"}>
                    Les informations personnelles telles que le nom, prénom, email, téléphone et données bancaires{" "}
                    <strong>
                        sont récoltées sur les sites des conciergeries partenaires et non pas sur {brandName}.
                    </strong>
                </li>
                <li className={"text-s leading-6"}>
                    Les conditions d’abonnement et la gestion des données personnelles sont mentionnées dans les{" "}
                    <strong>CGV et la charte de confidentialité des conciergeries partenaires.</strong>
                </li>
            </ol>
        </div>
    )
}
